//___PROJECT TOP___//

.projectTop {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 56px;
    &__img {
        width: 100%;
        height: 313px;
        position: relative;
        &__logo {
            width: 50%;
            height: auto;
            position: absolute;
            top: 156px;
            left: 25px;
            z-index: 10;
        }
        &__background {
            width: 100%;
            height: 100%;
            opacity: 20%;
        }
    }
    &__infos {
        padding-top: 46px;
        padding-left: 34px;
        padding-right: 34px;
        padding-bottom: 136px;
        z-index: 2;
        &__description {
            padding-bottom: 40px;
        }
        &__link {
            display: flex;
            justify-content: flex-end;
            margin-right: 10px;
        }
        &__group {
            padding-top: 42px;
            &__title {
                padding-bottom: 14px;
            }
        }
    }
    &__line {
        width: 82.6%;
        height: 1px;
        align-self: center;
        background-color: white;
    }
}

//______RESPONSIVE PROJECT TOP_____//
@media screen and (min-width: 996px) {
    .projectTop {
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        margin-bottom: 10%;
        &__img {
            display: none;
        }
        &__line {
            display: none;
        }
        &__infos {
            width: 28%;
            height: 30vh;
            padding-left: 0;
            padding-right: 6%;
            padding-top: 29vh;
        }
        &::after {
            width: 100vw;
            height: 50%;
            position: absolute;
            bottom: 0;
            content: '';
            z-index: 1;
            background: linear-gradient(rgba(39, 39, 39, 0) 0%, #1B1B1B 71.67%);
        }
    }
    .thumbnailDesktopBackground {
        width: 100vw;
        height: 100vh;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.2;
        filter: brightness(0.5);
    }
}

@media (hover: none) and (pointer: coarse) and (min-width: 996px) {
    .projectTop {
        &__infos {
            padding-top: 30vh;
        }
    }
}