//_____GALLERY PHOTOS_____//

.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 18%;
    &__img {
        height: 300px;
        flex-grow: 1;
        position: relative;
        margin: 0;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            transition: 0.5s;
        }
        &__infos {
            position: absolute;
            left: 10%;
            top: 30%;
            padding-right: 2%;
            opacity: 0;
            transition: 0.5s;
            h3 {
                padding-bottom: 10%;
            }
            &__date {
                padding-top: 40%;
                p {
                    padding-top: 5%;
                }
            }
        }
        &:hover .gallery__img__infos {
            opacity: 1;
            transition: 0.5s;
        }
        &:hover img {
            filter: grayscale(50%) brightness(0.3);
            transition: 0.5s;
        }
    }
}

//modal
.galleryPhotoModal {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 0;
    top: 0;
    padding-left: 4%;
    padding-right: 4%;
    position: fixed;
    z-index: 10;
    overflow: hidden;
    transition: 0.5s;
    &__img {
        max-width: 70%;
        max-height: 70%;
        z-index: 10;
    }
    &__arrow {
        width: auto;
        height: 6%;
        z-index: 10;
        cursor: pointer;
    }
    &__background {
        width: 100vw;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        background: rgba(10, 10, 10, 0.9);
    }
}

//_____RESPONSIVE GALLERY PHOTOS_____//

@media screen and (min-width: 996px) {
    .galleryPhotoModal {
        padding-left: 4%;
        padding-right: 4%;
        &__arrow {
            height: 8%;
        }
    }
}
