
//_____PROJECT DEV CONTENT_____//

.projectDevContent {
    padding-left: 34px;
    padding-right: 34px;
    padding-bottom: 100px;
    &__screen {
        margin-bottom: 50px;
        video {
            width: 100%;
        }
        &__img {
            width: 100%;
            height: auto;
            padding-top: 8px;
        }
    }
    video{
        width: 100%;
        height: 100%;
    }
}

//_____PROJECT DEV CONTENT RESPONSIVE_____//

@media screen and (min-width: 996px) {
    .projectDevContent {
        padding-left: 17%;
        padding-right: 17%;
    }
}