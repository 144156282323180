//_____Digital Artwork_____//

.digitalArtwork {
    width: 100%;
    height: auto;
    position: relative;
    padding-bottom: 30px;
    img {
        width: 100%;
        height: 100%;
    }
    &__description {
        position: absolute;
        left: 15%;
        top: 30%;
        opacity: 0;
        h2 {
            padding-bottom: 10%;
        }
        h3 {
            padding-top: 40%;
            padding-bottom: 5%;
        }
        &__project {
            margin-top: 10%;
            margin-bottom: 20%;
            padding: 8% 0 8% 25%;
            border: 2px solid;
        }
    }
    &:hover .digitalArtwork__description {
        opacity: 1;
        transition: 0.5s;
    }
    &:hover img {
        filter: grayscale(50%) brightness(0.3);
        transition: 0.5s;
    }
}

//_____RESPONSIVE DIGITAL ARTWORK_____//

@media screen and (min-width: 996px) {
    .digitalArtwork {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-self: center;
        padding-left: 20%;
        padding-right: 20%;
        padding-bottom: 20%;
        img {
            width: 100%;
        }
        &__title__desktop {
            align-self: flex-end;
            padding-top: 2vh;
        }
        &__description__project {
            margin-top: 10%;
            padding: 8% 0 8% 35%;
        }
    }
    .carousel {
        padding-left: 10%;
        padding-right: 10%;
    }
    #stairsAtNight {
        grid-column-start: 1;
        grid-row-start: 1;
    }
    #purpleLandscape {
        grid-column-start: 2;
        grid-row-start: 1;
    }
    #digitalConcepts {
        width: 200%;
        grid-column-start: 1;
        grid-column-end: 2;
    }
    #sfTower {
        grid-column-start: 1;
    }
    #digitalPortraits {
        width: 200%;
        padding-left: 50%;
        padding-right: 50%;
    }
    #alienMap {
        width: 200%;
        justify-self: auto;
        grid-column-start: 1;
        grid-column-end: 2;
        padding-left: 10%;
        padding-bottom: 90%;
    }
    //traditionalArtworks
    #acrylicHand {
        width: 180%;
        grid-column: 2;
        margin-right: 100%;
    }
    #traditionalPaintings {
        width: 200%;
        grid-column: 2;
        margin-left: -100%;
    }
    #traditionalPyrography {
        width: 200%;
    }
}

@media screen and (max-width: 996px) {
    .digitalArtwork__title__desktop {
        display: none;
    }
}

@media screen and (min-width: 996px) {
    .digitalArtwork__description {
        width: 50%;
        left: 25%;
        top: 15%;
    }
}
