//_____CAROUSEL DEV_____//

.carouselDev {
    width: 66.1%;
    height: 100vh;
    padding-left: 17.8%;
    padding-top: 193px;
    position: relative;
    // z-index: 10;
    &__title {
        position: relative;
        padding-left: 2%;
        padding-bottom: 2%;
        z-index: 1;
    }
    &__slide {
        position: relative;
        width: 100%;
        height: 45vh;
        z-index: 10;
        &__leftArrow {
            height: 10%;
            position: absolute;
            // left: -25px;
            left: -5%;
            top: 45%;
            z-index: 1;
            cursor: pointer;
            transition: 0.5s;
            &:hover {
                transform: scale(1.15);
                transition: 0.5s;
            }
        }
        &__rightArrow {
            height: 10%;
            position: absolute;
            // right: -25px;
            right: -5%;
            top: 45%;
            z-index: 1;
            cursor: pointer;
            transition: 0.5s;
            &:hover {
                transform: scale(1.15);
                transition: 0.5s;
            }
        }
        &__img {
            width: 100%;
            height: 100%;
        }
    }
    &__back {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 2%;
        img {
            object-fit: contain;
            height: auto;
            padding-right: 10%;
        }
        a {
            display: flex;
            padding-right: 72%;
            cursor: pointer;
            z-index: 2;
            transition: 0.5s;
            &:hover{
                transform: scale(1.05);
                transition: 0.5s;
            }
        }
        p {
            z-index: 2;
        }
    }
}
.linesProjectDev {
    width: 100%;
    height: 100%;
    position: absolute;
    padding-left: 17.8%;
    padding-right: 17.8%;
    opacity: 0.2;
}
.lastLineProjectDev {
    opacity: 0;
}

@media screen and (max-width: 996px) {
    .carouselDev {
        display: none;
    }
}
