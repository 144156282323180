//_____CAROUSEL BIS_____//

.carouselbis {
    padding-top: 0;
    padding-bottom: 60%;
    &__img {
        width: 75%;
        height: auto;
        margin-left: 10%;
        margin-right: 10%;
    }
}
.carouselbis .carousel__leftArrow{
    left: -4%;
}
.carouselbis .carousel__rightArrow{
    right: -4%;
}


//_____RESPONSIVE CAROUSEL BIS_____//
@media screen and (min-width: 996px) {
    //arrow
    .carouselbis .carousel__leftArrow{
        left: -7%;
    }
    .carouselbis .carousel__rightArrow{
        right: -7%;
    }
    //padding bottom
    .carouselbis{
        padding-bottom: 40%;
    }
}