//___FOOTER___//
footer {
    display: flex;
    flex-direction: column;
    padding-left: 9%;
    padding-bottom: 90px;
    background-color: $background-color;
    a {
        margin-bottom: 10px;
    }
}

//_____FOOTER RESPONSIVE_____//

@media screen and (min-width: 996px) {
    footer {
        flex-direction: row;
        padding-left: 18%;
        padding-top: 5%;
        padding-top: 5%;
        background-color: black;
        a {
            padding-right: 5%;
        }
    }
}
