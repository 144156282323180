
//___PROJECTS NEXT___//

.projectsNext {
    display: flex;
    justify-content: space-between;
    padding-left: 9%;
    padding-right: 9%;
    position: relative;
    &::after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: "";
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(217, 217, 217, 0.1) 100%);
    }
    a {
        cursor: pointer;
        padding-bottom: 37px;
        z-index: 10;
    }
}

//_____PROJECT NEXT_____//

@media screen and (min-width: 996px){
    .projectsNext{
        display: none;
    }
}